import {
  Typography,
  Grid,
  Avatar,
  List,
  ListItem,
  Button,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../utils/base";
import moment from "moment";
import "../ProfileDetails/Profile.css";
import { Apartment } from "@material-ui/icons";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InfoIcon from "@mui/icons-material/Info";
import { handlePropertyName } from "../../contextAPI/NamingConvention";
import { navigateToNoticeDetails } from "../../Navigation/Navigations";
import attachment from "../../assets/attachment.png";
import { AuthContext } from "../../contextAPI/context";

function AllNotices(props) {
  const navigate = useNavigate();
  const { authState } = useContext(AuthContext);
  const { plant } = authState;
  const { getNotices, pleasewait } = props;
  const [open, setOpen] = useState(false);
  const [noticeProperties, setnoticeProperties] = useState([]);

  const isUrl = (text) => {
    const urlPattern =
      /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,6})([\/\w.-]*)*\/?$/i;
    return urlPattern.test(text);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  // const downloadFiles = (urls) => {
  //   urls?.forEach((url, index) => {
  //     setTimeout(async () => {
  //       try {
  //         const response = await fetch(url);
  //         const blob = await response.blob();
  //         const link = document.createElement("a");
  //         link.href = URL.createObjectURL(blob);
  //         link.setAttribute("download", `file-${index + 1}`);
  //         document.body.appendChild(link);
  //         link.click();
  //         document.body.removeChild(link);
  //         URL.revokeObjectURL(link.href);
  //       } catch (error) {
  //         console.error(`Failed to download ${url}:`, error);
  //       }
  //     }, index * 500);
  //   });
  // };
  const downloadFiles = (urls) => {
    urls?.forEach((url, index) => {
      setTimeout(async () => {
        try {
          const response = await fetch(url);
          const blob = await response.blob();
          const link = document.createElement("a");

          // Extract the file extension from the URL
          const fileExtension = url.split(".").pop().split("?")[0]; // Handles query parameters
          link.setAttribute("download", `file-${index + 1}.${fileExtension}`);

          link.href = URL.createObjectURL(blob);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(link.href);
        } catch (error) {
          console.error(`Failed to download ${url}:`, error);
        }
      }, index * 500);
    });
  };

  const downloadSvg = (url) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="download-icon pointer border-0"
      onClick={() => downloadFiles(url)}
    >
      <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="" />
      <path
        d="M4 17V19C4 19.5304 4.21071 20.0391 4.58579 20.4142C4.96086 20.7893 5.46957 21 6 21H18C18.5304 21 19.0391 20.7893 19.4142 20.4142C19.7893 20.0391 20 19.5304 20 19V17M7 11L12 16M12 16L17 11M12 16V4"
        stroke="#03045E"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
  const buildingSvg = (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="building-icon pointer"
    >
      <path
        d="M2.25 15.75H15.75M3.75 15.75V5.25L9.75 2.25V15.75M14.25 15.75V8.25L9.75 5.25M6.75 6.75V6.7575M6.75 9V9.0075M6.75 11.25V11.2575M6.75 13.5V13.5075"
        stroke="#5E5E5E"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
  return (
    <>
      <div class="card-body px-0">
        <Grid className="d-flex justify-content-center">
          {getNotices?.length > 0 && (
            <>
              <Grid container spacing={3} xs={12} className="d-flex">
                {getNotices?.map((notice, index) => {
                  return (
                    <Grid item xs={4}>
                      <div
                        className="noticemain-card"
                        style={{
                          boxShadow:
                            "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                        }}
                      >
                        <div className="noticemain-title pointer">
                          {notice?.noticeTitle?.length > 18
                            ? notice?.noticeTitle.slice(0, 18).concat("...")
                            : notice?.noticeTitle}
                        </div>
                        <div className="noticemain-society pointer">
                          {buildingSvg}
                          {notice?.propertyName?.length > 0
                            ? notice?.propertyName?.length +
                              ` Selected ${handlePropertyName()}`
                            : plant
                            ? "Plant Wise"
                            : `Society Wise`}
                          {notice?.propertyName?.length > 0 && (
                            <InfoIcon
                              title="Porperty Info"
                              style={{
                                color: "#2f45c5",
                                cursor: "pointer",
                                marginLeft: 10,
                                fontSize: 19,
                              }}
                              onClick={() => {
                                setnoticeProperties(notice?.propertyName);
                                setOpen(true);
                              }}
                            />
                          )}
                        </div>
                        <div className="col-sm-12 d-flex">
                          {" "}
                          <div
                            className="noticemain-emergency pointer text-capitalize fs-6"
                            style={{
                              color: `
                              ${
                                notice?.isPublished === "published"
                                  ? "#2f45c5"
                                  : "#e1ad01"
                              } `,
                              border: `${
                                notice?.isPublished === "published"
                                  ? "0.5px solid #2f45c5"
                                  : "0.5px solid #e1ad01"
                              }`,
                            }}
                          >
                            {notice?.isPublished}
                          </div>
                          <div
                            className="noticemain-emergency pointer ms-3"
                            style={{
                              color: `
                              ${
                                notice?.noticeType?.name === "Casual"
                                  ? "#2f45c5"
                                  : "#d44026"
                              } `,
                              border: `${
                                notice?.noticeType?.name === "Casual"
                                  ? "1px solid #2f45c5"
                                  : "1px solid #d44026"
                              }`,
                            }}
                          >
                            {notice?.noticeType?.name}
                          </div>
                        </div>

                        <div
                          className="noticemain-description pointer text-truncate"
                          style={{ width: "23vw" }}
                        >
                          {isUrl(notice?.noticeDescription) ? (
                            <a
                              href={notice.noticeDescription}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {notice.noticeDescription}
                            </a>
                          ) : (
                            notice?.noticeDescription
                          )}
                        </div>
                        <div className="noticemain-attachments h-25">
                          <div className="noticemain-images">
                            <span>
                              {" "}
                              {notice?.images?.length > 0
                                ? +notice?.images?.length + " Images"
                                : "No Images"}{" "}
                              &nbsp;{" "}
                              {notice?.images?.length > 0 &&
                                downloadSvg(
                                  notice?.images?.map((img) => img?.Location)
                                )}
                            </span>

                            <div
                              className="image-icons"
                              onClick={() => {
                                navigate(
                                  navigateToNoticeDetails({
                                    noticeId: notice?._id,
                                  })
                                );
                              }}
                            >
                              {notice?.images?.map((img, index) => (
                                <img
                                  src={
                                    img
                                      ? img?.Location
                                      : "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                                  }
                                  alt={index}
                                  key={index}
                                  className="image-icon"
                                />
                              ))}
                            </div>
                          </div>
                          <div className="noticemain-attachment">
                            <span>
                              {" "}
                              {notice?.attachments?.length > 0
                                ? notice?.attachments?.length + " Attachments"
                                : "No Attachments"}{" "}
                              &nbsp;{" "}
                              {notice?.attachments?.length > 0 &&
                                downloadSvg(
                                  notice?.attachments?.map(
                                    (att) => att?.Location
                                  )
                                )}
                            </span>
                            {notice?.attachments?.length > 0 && (
                              <div className="attachment-icon ">
                                <img
                                  src={attachment}
                                  alt="PDF Icon"
                                  className="pdf-icon"
                                />
                                &nbsp;
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className="noticemain-footer d-flex justify-content-between  pointer"
                          onClick={() => {
                            navigate(
                              navigateToNoticeDetails({
                                noticeId: notice?._id,
                              })
                            );
                          }}
                        >
                          <div className="admin-details mt-2">
                            <div className="admin-avatar">
                              <Avatar
                                src={
                                  notice?.publishedBy?.image
                                    ? `${BASE_URL}/user/${notice?.publishedBy?.image}`
                                    : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                                }
                              />
                            </div>{" "}
                            &nbsp;
                            <div className="admin-info ">
                              <span className="text-capitalize fw-semibold">
                                {notice?.publishedBy?.name}
                              </span>
                              <br />
                              <span
                                style={{ color: "#979797", fontSize: "12px" }}
                              >
                                {" "}
                                {moment(notice?.createdAt).format(
                                  "DD MMM, YYYY, HH:mm"
                                )}
                              </span>
                            </div>
                          </div>
                          <svg
                            width="50"
                            height="50"
                            viewBox="0 0 62 62"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="62"
                              height="62"
                              rx="31"
                              fill="#3862DD"
                            />
                            <path
                              d="M15.042 31H45.9587M45.9587 31L32.7087 43.5M45.9587 31L32.7087 18.5"
                              stroke="white"
                              stroke-width="4"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </>
          )}

          {getNotices?.length === 0 && !pleasewait && (
            <Typography
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: 19,
                color: "#2f3aa3",
              }}
            >
              No Notice
            </Typography>
          )}
        </Grid>
      </div>
      <Dialog
        open={open}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Typography
            style={{
              fontFamily: "Montserrat, sans-serif",
              color: "#6c757d",
              fontSize: 13,
              fontWeight: "bolder",
              marginBottom: 10,
              display: "flex",
            }}
          >
            Notice Selected {handlePropertyName()} Details
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container style={{ justifyContent: "space-between" }}>
            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 15,
                marginBottom: 5,
              }}
            >
              Name
            </Typography>

            <Typography
              style={{
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontSize: 15,
                marginRight: 20,
                marginBottom: 5,
                marginLeft: 20,
              }}
            >
              Sector
            </Typography>
          </Grid>
          <Grid>
            <List>
              {noticeProperties?.length ? (
                <>
                  {noticeProperties?.map((property) => {
                    return (
                      <ListItem>
                        <Apartment
                          style={{
                            fontSize: 15,
                            color: "#6c757d",
                            marginRight: 4,
                            marginLeft: -17,
                          }}
                        />
                        <Grid
                          container
                          style={{ justifyContent: "space-between" }}
                        >
                          <Typography style={{ fontSize: 15 }}>
                            {property?.name}
                          </Typography>
                          <Typography style={{ fontSize: 15 }}>
                            {property?.sector}
                          </Typography>
                        </Grid>
                      </ListItem>
                    );
                  })}
                </>
              ) : (
                <Grid container style={{ justifyContent: "center" }}>
                  <Typography
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      fontSize: 15,
                      marginBottom: 5,
                    }}
                  >
                    No {handlePropertyName()} Selected Yet
                  </Typography>
                </Grid>
              )}
            </List>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AllNotices;
