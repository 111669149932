import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper } from "@material-ui/core";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import { Typography, Grid } from "@material-ui/core";
import { Circle } from "@mui/icons-material";
import { History } from "@material-ui/icons";
import { handleFlatName } from "../../../../contextAPI/NamingConvention";
import { navigateToFlatTimeline } from "../../../../Navigation/Navigations";
import { isMemberTypeS2Admin } from "../../../../GlobalFunctions/GlobalFunctions";
import EditIcon from "@mui/icons-material/Edit";

function FlatTable(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    floors,
    propertyId,
    propertyName,
    societyName,
    user,
    seteditedFlatDetails,
    setisFlatEdit,
  } = props;

  return (
    <>
      {floors?.map((flr) => {
        return (
          <>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: 22,
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                marginLeft: 15,
              }}
            >
              <Circle style={{ marginRight: 4, fontSize: 14 }} />
              {flr?.name}
            </Typography>
            <TableContainer
              component={Paper}
              className={classes.tableContainer}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      className={classes.tableHeaderCell}
                      sx={{
                        color: "white",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      Sr. No.
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableHeaderCell}
                      sx={{
                        color: "white",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {/* Flat Name */}
                      {handleFlatName()} Name
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableHeaderCell}
                      sx={{
                        color: "white",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {handleFlatName()} Number
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableHeaderCell}
                      sx={{
                        color: "white",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      R. Unit
                    </TableCell>

                    <TableCell
                      align="center"
                      className={classes.tableHeaderCell}
                      sx={{
                        color: "white",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {handleFlatName()} Type
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableHeaderCell}
                      sx={{
                        color: "white",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {handleFlatName()} Status
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableHeaderCell}
                      sx={{
                        color: "white",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      Activity
                    </TableCell>
                    {isMemberTypeS2Admin(user?.role) && (
                      <TableCell
                        align="center"
                        className={classes.tableHeaderCell}
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Action
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {flr?.flats?.length !== 0 ? (
                    <>
                      {flr?.flats?.map((flt, index) => {
                        return (
                          <TableRow>
                            <TableCell
                              align="center"
                              sx={{
                                color: "black",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              {index + 1}.
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                color: "black",
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: "bold",
                              }}
                            >
                              {flt?.name}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                color: "black",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              {flt?.number}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                color: "black",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              {flt?.runit ? flt?.runit : "-"}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                color: "black",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              {flt?.type}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                color: "black",
                                fontFamily: "Poppins, sans-serif",
                              }}
                              className="text-capitalize"
                            >
                              {flt?.isResidingOwner === true
                                ? "occupied"
                                : flt?.occupancyStatus}
                            </TableCell>

                            <TableCell
                              align="center"
                              sx={{
                                color: "black",
                                fontFamily: "Poppins, sans-serif",
                                cursor: "pointer",
                              }}
                            >
                              <History
                                onClick={() => {
                                  navigate(
                                    navigateToFlatTimeline({
                                      societyName,
                                      propertyName,
                                      propertyId,
                                      flatName: flt?.name,
                                      flatId: flt?._id,
                                    })
                                  );
                                }}
                                style={{ fontSize: 25, color: "#2f3aa3" }}
                              />
                            </TableCell>
                            {isMemberTypeS2Admin(user?.role) && (
                              <TableCell
                                align="center"
                                sx={{
                                  color: "black",
                                  fontFamily: "Poppins, sans-serif",
                                  cursor: "pointer",
                                }}
                              >
                                <EditIcon
                                  onClick={() => {
                                    seteditedFlatDetails(flt);
                                    setisFlatEdit(true);
                                  }}
                                  style={{ fontSize: 25, color: "#2f3aa3" }}
                                />
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                    </>
                  ) : (
                    <Grid container style={{ justifyContent: "center" }}>
                      <Typography
                        style={{
                          fontFamily: "Montserrat, sans-serif",
                          color: "#6c757d",
                          fontSize: 17,
                          marginTop: 7,
                          fontWeight: "bolder",
                          marginBottom: 10,
                          textAlign: "center",
                        }}
                      >
                        No {handleFlatName()}
                      </Typography>
                    </Grid>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        );
      })}
    </>
  );
}

export default FlatTable;
