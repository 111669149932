import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Select,
  MenuItem,
  Button,
  Badge,
  CardMedia,
  Card,
  Checkbox,
} from "@material-ui/core";
import useStyles from "../style";
import Modal from "@mui/material/Modal";
import { AddCircle, Close, Cancel, Error } from "@material-ui/icons";
import { Input } from "antd";
import { AuthContext } from "../../../contextAPI/context";
import * as api from "../../../api/index";
import { useContext } from "react";
import { resolveErrorMessage } from "../../../services/service";
import { LoadingOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import {
  handlePreviewFileInLocal,
  handleUploadImgFile,
  handleUploadInDirectAws,
  handleUploadInLocal,
} from "../../../utils/awsUpload";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SearchAddress from "../../../GlobalComponents/SearchAddress/SearchAddress";
import { handlePropertyName } from "../../../contextAPI/NamingConvention";
import AddNoticeType from "./AddNoticeType";

function AddNotice(props) {
  const classes = useStyles();
  const { authState } = useContext(AuthContext);
  const { user, plant } = authState;
  const { AddNoticeModal, setAddNoticeModal, handleGetNotices, setPage, page } =
    props;
  const [noticeTitle, setnoticeTitle] = useState("");
  const [selectNoticeType, setselectNoticeType] = useState("Select Type");
  const { TextArea } = Input;
  const [noticeDescription, setNoticeDescription] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [noticeType, setNoticeType] = useState([]);
  const [openModalAddType, setopenModalAddType] = useState(false);
  const [loader, setloader] = useState(false);
  const [viewerType, setviewerType] = useState("societyWise");
  // IMAGE
  const [image1, setimage1] = useState(null);
  const [image2, setimage2] = useState(null);
  const [image3, setimage3] = useState(null);

  // ATTACHMENTS
  const [attach1, setattach1] = useState(null);
  const [attach2, setattach2] = useState(null);
  const [attach3, setattach3] = useState(null);

  //selection / property Modal
  const [propertyId, setPropertyId] = useState([]);
  const [searchKey, setsearchKey] = useState("");
  //property Selection in input

  const [type, settype] = useState("published");

  const handleNoticeFor = (event) => {
    setselectNoticeType(event.target.value);
  };

  const handlereset = () => {
    setAddNoticeModal(false);
    setnoticeTitle("");
    setselectNoticeType("Select Type");
    setPropertyId([]);
    setNoticeDescription("");
    setsearchKey("");
    setPage(1);
    setimage1(null);
    setimage2(null);
    setimage3(null);
    setattach1(null);
    setattach2(null);
    setattach3(null);
  };

  const handleNoticeType = async () => {
    try {
      const res = await api.getNoticeType({
        params: { societyId: fetchSocietyId() },
      });

      if (res?.data?.success) {
        setNoticeType(res?.data?.noticeType);
      }
    } catch (error) {}
  };

  const isInvalidProperty = () => {
    let invalid = false;
    propertyId?.map((p) => {
      if (!p?.societyId) {
        invalid = true;
      } else {
        invalid = false;
      }
    });
    return invalid;
  };

  const handleAddNotices = async () => {
    setPage(1);
    seterrorMsg("");
    if (noticeTitle === "") {
      seterrorMsg("Notice Title is required");
    } else if (selectNoticeType === "Select Type") {
      seterrorMsg("Notice Type is required");
    } else if (noticeDescription === "") {
      seterrorMsg("Notice Description is required");
    } else if (propertyId.length === 0 && viewerType === "propertyWise") {
      seterrorMsg("Select atleast one property");
    } else if (isInvalidProperty() && viewerType === "propertyWise") {
      seterrorMsg("Please select a valid property");
    } else {
      setloader(true);
      setTimeout(() => {
        setloader(false);
      }, 40000);
      let uploadedImage = await handleUploadImgFile(image1);
      let uploadedAttach = await handleUploadImgFile(attach1);
      try {
          const sendData = {
            societyId: fetchSocietyId(),
            noticeTitle: noticeTitle,
            noticeType: selectNoticeType,
            noticeDescription: noticeDescription,
            publishedBy: user?._id,
            publishedStatus: type,
            propertyId: propertyId?.map((x) => {
              return x._id;
            }),
            images: [{Location:uploadedImage}],
            attachments: [{Location:uploadedAttach}],
          };
          const res = await api.addNotice(sendData);
          if (res?.data?.success) {
            if (type === "published") {
              toast.success("Notice has been published Successfully", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
              });
            } else {
              toast.info("Notice Saved as a darft", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
              });
            }

            setloader(false);
            handleGetNotices("all");
            handlereset();
          }
        
      } catch (error) {
        seterrorMsg(resolveErrorMessage(error));
        setloader(false);
      }
    }
  };

  useEffect(() => {
    handleNoticeType();
  }, []);

  return (
    <>
      <Modal open={AddNoticeModal}>
        <Paper
          style={{
            backgroundColor: "#ffff",
            boxShadow: "2px 2px 8px #000",
            borderRadius: 7,
            minWidth: "60vw",
          }}
          className={classes.ModalView}
        >
          <Grid
            container
            style={{
              textAlign: "center",
              justifyContent: "space-between",
              marginBottom: "12px",
            }}
          >
            <span className="page-header">Create Notice</span>

            <IconButton
              onClick={() => {
                handlereset();
              }}
              className="pointer"
            >
              <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
            </IconButton>
          </Grid>

          <div
            className="col-sm-12 d-flex justify-content-between scrollable"
            style={{
              maxHeight: "70vh",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <div className="col-sm-6 row p-3">
              <div className="col-sm-12 mb-4">
                <label className="form-label fs-6">Notice Title</label>

                <input
                  className="form-control p-2"
                  type="text"
                  placeholder="Enter title of notice"
                  required
                  value={noticeTitle}
                  maxLength={300}
                  onChange={(e) => {
                    setnoticeTitle(e.target.value);
                  }}
                />
              </div>
              <div className="col-sm-12 mb-4">
                <label className="form-label fs-6"> Select Viewer Type </label>

                <Select
                  value={viewerType}
                  onChange={(event) => {
                    setviewerType(event.target.value);
                    if (event.target.value === "societyWise") {
                      setPropertyId([]);
                    }
                  }}
                  style={{
                    display: "block",
                    color: "#343a40",
                    fontFamily: "Poppins, sans-serif",
                    backgroundColor: "#fff",
                    borderRadius: 6,
                    fontSize: 16,
                    padding: 4,
                    paddingLeft: 20,
                    border: "0.5px solid #979797",
                  }}
                  onClick={() => {
                    seterrorMsg("");
                  }}
                >
                  <MenuItem selected value="societyWise">
                    {plant ? "Plant Wise" : "Society Wise"}
                  </MenuItem>
                  <MenuItem selected value="propertyWise">
                    {handlePropertyName()} Wise
                  </MenuItem>
                </Select>
              </div>

              {viewerType === "propertyWise" && (
                <div className="col-sm-12 mb-4">
                  <SearchAddress
                    searchKey={searchKey}
                    setsearchKey={setsearchKey}
                    setPropertyId={setPropertyId}
                    propertyId={propertyId}
                    isMultiple={true}
                  />
                </div>
              )}

              <div className="col-sm-12 mb-4">
                <label className="form-label fs-6">Description</label>

                <TextArea
                  placeholder="Description here"
                  rows={6}
                  className="custom"
                  style={{
                    borderRadius: 10,
                  }}
                  onChange={(event) => setNoticeDescription(event.target.value)}
                  value={noticeDescription}
                  maxLength={1000}
                />
              </div>
            </div>

            <div className="col-sm-6 row p-3">
              {" "}
              <div className="col-sm-12 mb-4">
                <label className="form-label fs-6">
                  Notice Type &nbsp;{" "}
                  <AddCircle
                    title="Add Notice Type"
                    onClick={() => {
                      setopenModalAddType(true);
                    }}
                    style={{
                      color: "#2f3aa3",
                      cursor: "pointer",
                      fontSize: "18px",
                    }}
                  />
                </label>

                <Select
                  value={selectNoticeType}
                  onChange={handleNoticeFor}
                  style={{
                    display: "block",
                    color: "#343a40",
                    fontFamily: "Poppins, sans-serif",
                    backgroundColor: "#fff",
                    borderRadius: 6,
                    padding: 4,
                    fontSize: 16,
                    paddingLeft: 20,
                    border: "0.5px solid #979797",
                  }}
                >
                  <MenuItem selected value="Select Type">
                    Select Type
                  </MenuItem>
                  {noticeType?.map((notice) => {
                    return (
                      <MenuItem
                        style={{ textTransform: "capitalize" }}
                        selected
                        value={notice?._id}
                      >
                        {notice?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div className="col-sm-12 mb-4">
                <label className="form-label fs-6">
                  Images <span className="text-secondary">(max size 1mb)</span>
                </label>
                <div className="col-am-12 d-flex gap-1">
                  <div className="col-sm-4">
                    {image1 ? (
                      <Badge
                        badgeContent={
                          <Cancel
                            style={{
                              color: "red",
                              cursor: "pointer",
                              fontSize: 22,
                            }}
                            onClick={() => {
                              setimage1(null);
                            }}
                          />
                        }
                      >
                        <div
                          style={{
                            backgroundColor: "#e6e7f0",
                            maxWidth: "115px",
                            width: "115px",
                            height: "100px",
                            border: "1px dashed #3862DD",
                          }}
                        >
                          <CardMedia
                            component="img"
                            style={{
                              objectFit: "scale-down",
                              maxWidth: "115px",
                              width: "115px",
                              height: "100px",
                            }}
                            image={
                              image1
                                ? handlePreviewFileInLocal(image1)
                                : "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                            }
                            alt="Image1"
                          />
                        </div>
                      </Badge>
                    ) : (
                      <div
                        style={{
                          justifyContent: "center",
                          textAlign: "center",
                          padding: "20px",
                          border: "1px dashed #3862DD",
                          maxWidth: "115px",
                          height: "100px",
                        }}
                      >
                        <label className="pointer">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.33398 22.6668V25.3335C5.33398 26.0407 5.61494 26.719 6.11503 27.2191C6.61513 27.7192 7.29341 28.0002 8.00065 28.0002H24.0007C24.7079 28.0002 25.3862 27.7192 25.8863 27.2191C26.3864 26.719 26.6673 26.0407 26.6673 25.3335V22.6668M9.33398 12.0002L16.0007 5.3335M16.0007 5.3335L22.6673 12.0002M16.0007 5.3335V21.3335"
                              stroke="#3862DD"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <br />
                          <span
                            style={{
                              color: "#2f45c5",
                              textAlign: "center",
                              fontSize: "8px",
                              width: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Upload image
                          </span>
                          <input
                            type="file"
                            onChange={(event) => {
                              setimage1(event)
                            }}
                            style={{
                              width: 0,
                              outline: 0,
                              backgroundColor: "#4b8edb",
                              height: 0,
                              marginBottom: 0,
                            }}
                            accept="image/png,image/jpeg"
                          ></input>
                        </label>
                      </div>
                    )}
                  </div>
                  {/* <div className="col-sm-4">
                    {image2?.data ? (
                      <Badge
                        badgeContent={
                          <Cancel
                            style={{
                              color: "red",
                              cursor: "pointer",
                              fontSize: 22,
                            }}
                            onClick={() => {
                              setimage2({ data: "" });
                            }}
                          />
                        }
                      >
                        <Card
                          style={{
                            backgroundColor: "#e6e7f0",
                            maxWidth: "115px",
                            width: "115px",
                            height: "100px",
                            border: "1px dashed #3862DD",
                          }}
                        >
                          <CardMedia
                            component="img"
                            style={{
                              objectFit: "scale-down",
                              maxWidth: "115px",
                              width: "115px",
                              height: "100px",
                            }}
                            image={
                              image2?.data
                                ? image2?.data
                                : "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                            }
                            alt="Image2"
                          />
                        </Card>
                      </Badge>
                    ) : (
                      <div
                        style={{
                          justifyContent: "center",
                          textAlign: "center",
                          padding: "20px",
                          border: `${
                            image1?.data
                              ? "1px dashed #3862DD"
                              : "1px dashed  #979797"
                          }`,
                          maxWidth: "115px",
                          height: "100px",
                        }}
                      >
                        <label className="pointer">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.33398 22.6668V25.3335C5.33398 26.0407 5.61494 26.719 6.11503 27.2191C6.61513 27.7192 7.29341 28.0002 8.00065 28.0002H24.0007C24.7079 28.0002 25.3862 27.7192 25.8863 27.2191C26.3864 26.719 26.6673 26.0407 26.6673 25.3335V22.6668M9.33398 12.0002L16.0007 5.3335M16.0007 5.3335L22.6673 12.0002M16.0007 5.3335V21.3335"
                              stroke="#3862DD"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <br />
                          <span
                            style={{
                              color: "#2f45c5",
                              textAlign: "center",
                              fontSize: "8px",
                              width: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Upload image
                          </span>
                          <input
                            type="file"
                            disabled={!image1?.data}
                            onChange={(event) => {
                              handleUploadInLocal(event, "image", setimage2);
                            }}
                            style={{
                              width: 0,
                              outline: 0,
                              backgroundColor: "#4b8edb",
                              height: 0,
                              marginBottom: 0,
                              cursor: "pointer",
                            }}
                            accept="image/png,image/jpeg"
                          ></input>
                        </label>
                      </div>
                    )}
                  </div>
                  <div className="col-sm-4">
                    {image3?.data ? (
                      <Badge
                        badgeContent={
                          <Cancel
                            style={{
                              color: "red",
                              cursor: "pointer",
                              fontSize: 22,
                            }}
                            onClick={() => {
                              setimage3({ data: "" });
                            }}
                          />
                        }
                      >
                        <Card
                          style={{
                            backgroundColor: "#e6e7f0",
                            maxWidth: "115px",
                            width: "115px",
                            height: "100px",
                            border: "1px dashed #3862DD",
                          }}
                        >
                          <CardMedia
                            component="img"
                            style={{
                              objectFit: "scale-down",
                              maxWidth: "115px",
                              width: "115px",
                              height: "100px",
                            }}
                            image={
                              image3?.data
                                ? image3?.data
                                : "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                            }
                            alt="Image3"
                          />
                        </Card>
                      </Badge>
                    ) : (
                      <div
                        style={{
                          justifyContent: "center",
                          textAlign: "center",
                          padding: "20px",
                          border: `${
                            image2?.data
                              ? "1px dashed #3862DD"
                              : "1px dashed  #979797"
                          }`,
                          maxWidth: "115px",
                          height: "100px",
                        }}
                      >
                        <label className="pointer">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.33398 22.6668V25.3335C5.33398 26.0407 5.61494 26.719 6.11503 27.2191C6.61513 27.7192 7.29341 28.0002 8.00065 28.0002H24.0007C24.7079 28.0002 25.3862 27.7192 25.8863 27.2191C26.3864 26.719 26.6673 26.0407 26.6673 25.3335V22.6668M9.33398 12.0002L16.0007 5.3335M16.0007 5.3335L22.6673 12.0002M16.0007 5.3335V21.3335"
                              stroke="#3862DD"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <br />
                          <span
                            style={{
                              color: "#2f45c5",
                              textAlign: "center",
                              fontSize: "8px",
                              width: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Upload image
                          </span>
                          <input
                            type="file"
                            disabled={!image2?.data}
                            onChange={(event) => {
                              handleUploadInLocal(event, "image", setimage3);
                            }}
                            style={{
                              width: 0,
                              outline: 0,
                              backgroundColor: "#4b8edb",
                              height: 0,
                              marginBottom: 0,
                            }}
                            accept="image/png,image/jpeg"
                          ></input>
                        </label>
                      </div>
                    )}
                  </div> */}
                </div>
              </div>
              <div className="col-sm-12 mb-4">
                <label className="form-label fs-6">
                  Attachments{" "}
                  <span className="text-secondary">(max size 100kb)</span>
                </label>

                <div className="col-sm-12 d-flex">
                  <div className="col-sm-4">
                    {attach1 ? (
                      <Badge
                        badgeContent={
                          <Cancel
                            style={{
                              color: "red",
                              cursor: "pointer",
                              fontSize: 22,
                            }}
                            onClick={() => {
                              setattach1(null);
                            }}
                          />
                        }
                      >
                        <div
                          style={{
                            backgroundColor: "#e6e7f0",
                            maxWidth: "115px",
                            width: "115px",
                            height: "100px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px dashed #3862DD",
                          }}
                        >
                          <AttachFileIcon
                            style={{
                              width: "60px",
                              height: "60px",
                              color: "#2f45c5",
                            }}
                          />
                        </div>
                      </Badge>
                    ) : (
                      <div
                        style={{
                          justifyContent: "center",
                          textAlign: "center",
                          padding: "20px",
                          border: "1px dashed #3862DD",
                          maxWidth: "115px",
                          height: "100px",
                        }}
                      >
                        <label className="pointer">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.33398 22.6668V25.3335C5.33398 26.0407 5.61494 26.719 6.11503 27.2191C6.61513 27.7192 7.29341 28.0002 8.00065 28.0002H24.0007C24.7079 28.0002 25.3862 27.7192 25.8863 27.2191C26.3864 26.719 26.6673 26.0407 26.6673 25.3335V22.6668M9.33398 12.0002L16.0007 5.3335M16.0007 5.3335L22.6673 12.0002M16.0007 5.3335V21.3335"
                              stroke="#3862DD"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <br />
                          <span
                            style={{
                              color: "#2f45c5",
                              textAlign: "center",
                              fontSize: "8px",
                              width: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Upload attachment
                          </span>
                          <input
                            type="file"
                            onChange={(event) => {
                              setattach1(event)
                            }}
                            style={{
                              width: 0,
                              outline: 0,
                              backgroundColor: "#4b8edb",
                              height: 0,
                              marginBottom: 0,
                            }}
                          ></input>
                        </label>
                      </div>
                    )}
                  </div>
                  {/* <div className="col-sm-4">
                    {attach2?.data ? (
                      <Badge
                        badgeContent={
                          <Cancel
                            style={{
                              color: "red",
                              cursor: "pointer",
                              fontSize: 22,
                            }}
                            onClick={() => {
                              setattach2({ data: "" });
                            }}
                          />
                        }
                      >
                        <div
                          style={{
                            backgroundColor: "#e6e7f0",
                            maxWidth: "115px",
                            width: "115px",
                            height: "100px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px dashed #3862DD",
                          }}
                        >
                          <AttachFileIcon
                            style={{
                              width: "60px",
                              height: "60px",
                              color: "#2f45c5",
                            }}
                          />
                        </div>
                      </Badge>
                    ) : (
                      <div
                        style={{
                          justifyContent: "center",
                          textAlign: "center",
                          padding: "20px",
                          border: `${
                            attach1?.data
                              ? "1px dashed #3862DD"
                              : "1px dashed  #979797"
                          }`,
                          maxWidth: "115px",
                          height: "100px",
                        }}
                      >
                        <label className="pointer">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.33398 22.6668V25.3335C5.33398 26.0407 5.61494 26.719 6.11503 27.2191C6.61513 27.7192 7.29341 28.0002 8.00065 28.0002H24.0007C24.7079 28.0002 25.3862 27.7192 25.8863 27.2191C26.3864 26.719 26.6673 26.0407 26.6673 25.3335V22.6668M9.33398 12.0002L16.0007 5.3335M16.0007 5.3335L22.6673 12.0002M16.0007 5.3335V21.3335"
                              stroke="#3862DD"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <br />
                          <span
                            style={{
                              color: "#2f45c5",
                              textAlign: "center",
                              fontSize: "8px",
                              width: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Upload attachment
                          </span>
                          <input
                            type="file"
                            disabled={!attach1?.data}
                            onChange={(event) => {
                              handleUploadInLocal(event, "attach", setattach2);
                            }}
                            style={{
                              width: 0,
                              outline: 0,
                              backgroundColor: "#4b8edb",
                              height: 0,
                              marginBottom: 0,
                            }}
                          ></input>
                        </label>
                      </div>
                    )}
                  </div>

                  <div className="col-sm-4">
                    {attach3?.data ? (
                      <Badge
                        badgeContent={
                          <Cancel
                            style={{
                              color: "red",
                              cursor: "pointer",
                              fontSize: 22,
                            }}
                            onClick={() => {
                              setattach3({ data: "" });
                            }}
                          />
                        }
                      >
                        <div
                          style={{
                            backgroundColor: "#e6e7f0",
                            maxWidth: "115px",
                            width: "115px",
                            height: "100px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px dashed #3862DD",
                          }}
                        >
                          <AttachFileIcon
                            style={{
                              width: "60px",
                              height: "60px",
                              color: "#2f45c5",
                            }}
                          />
                        </div>
                      </Badge>
                    ) : (
                      <div
                        style={{
                          justifyContent: "center",
                          textAlign: "center",
                          padding: "20px",
                          border: `${
                            attach2?.data
                              ? "1px dashed #3862DD"
                              : "1px dashed  #979797"
                          }`,
                          maxWidth: "115px",
                          height: "100px",
                        }}
                      >
                        <label>
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.33398 22.6668V25.3335C5.33398 26.0407 5.61494 26.719 6.11503 27.2191C6.61513 27.7192 7.29341 28.0002 8.00065 28.0002H24.0007C24.7079 28.0002 25.3862 27.7192 25.8863 27.2191C26.3864 26.719 26.6673 26.0407 26.6673 25.3335V22.6668M9.33398 12.0002L16.0007 5.3335M16.0007 5.3335L22.6673 12.0002M16.0007 5.3335V21.3335"
                              stroke="#3862DD"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <br />
                          <span
                            style={{
                              color: "#2f45c5",
                              textAlign: "center",
                              fontSize: "8px",
                              width: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Upload attachment
                          </span>
                          <input
                            type="file"
                            disabled={!attach2?.data}
                            onChange={(event) => {
                              handleUploadInLocal(event, "attach", setattach3);
                            }}
                            style={{
                              width: 0,
                              outline: 0,
                              backgroundColor: "#4b8edb",
                              height: 0,
                              marginBottom: 0,
                            }}
                          ></input>
                        </label>
                      </div>
                    )}
                  </div> */}
                </div>
              </div>
              <div className="col-sm-12 d-flex mb-4">
                <Grid>
                  <Checkbox
                    color="primary"
                    checked={type === "published"}
                    onClick={() => {
                      settype("published");
                    }}
                  />
                  Published
                </Grid>
                <Grid style={{ marginLeft: "15px" }}>
                  <Checkbox
                    color="primary"
                    checked={type === "unpublished"}
                    onClick={() => {
                      settype("unpublished");
                    }}
                  />
                  Save as Draft
                </Grid>
              </div>
            </div>
          </div>

          <div className="col-sm-12 d-flex mt-2">
            <div className="col-sm-6">
              {" "}
              {errorMsg && (
                <Typography
                  style={{
                    marginTop: 20,
                    color: "red",
                    textAlign: "center",
                    fontSize: 13,
                    fontWeight: "bold",
                  }}
                >
                  <Error /> {errorMsg}
                </Typography>
              )}
            </div>
            <div className="col-sm-6 d-flex justify-content-end">
              <Button
                style={{
                  padding: "8px",
                  paddingLeft: 15,
                  paddingRight: 15,
                  width: "70%",
                  backgroundColor: "#3862DD",
                  color: "#fff",
                }}
                disabled={loader}
                variant="contained"
                size="large"
                onClick={() => {
                  handleAddNotices();
                }}
              >
                {loader && <LoadingOutlined />}&nbsp; Publish Notice
              </Button>
            </div>
          </div>
        </Paper>
      </Modal>
      <AddNoticeType
        open={openModalAddType}
        setOpen={setopenModalAddType}
        handleNoticeType={handleNoticeType}
      />
    </>
  );
}

export default AddNotice;
